export const chatbotSettings = {
         userInputWidth: 85,
         userInputHeight: 30,
         userInputBorderRadius: 5,
         userInputBackgroundColor: '#FFFFFF',
         chatbotWidth: 425,
         chatbotHeight: 600,
         enableStaticStartMessage: true,
         startMessage: 'Hallo, waarmee kan ik je helpen?',
         language: 'nl',
         allowEmoticons: false,
         allowAttachments: false,
         allowPlaySounds: false,
         streamingResponse: true,
         showRating: false,
         playSoundSrc: '',
         showMenu: false,
         chatbotFooterBackgroundColor: '#4100E1',
         showDateTime: true,
         messageDateFontSize: 14,
         showOmniChanels: true,
         showSyncColor: false,
         syncColor: '#FFFFFF',
         includeBotAvatar: true,
         typeImageIcon: 'image',
         botImageSrc: 'https://hosthubconfigurationtest.azurewebsites.net//chatbot/settings/image/download?configuration_id=3263368c-8eaa-4288-abf5-fd329b2fd318&filename=b2663643-c3e8-4054-aa1b-d69628757008',
         botIconSrc: '/icons/bot.svg',
         botImageIconSize: 55,
         botIconBackgroundColor: '#C9F1FD',
         includeChatHeader: true,
         headerText: 'Chat met [NAAM]',
         headerFontType: 'Arial',
         headerFontSize: 25,
         headerTextColor: '#FFFFFF',
         onlineMessage: 'Wij antwoorden direct!',
         onlineTextColor: '#FFFFFF',
         poweredByTextColor: '#FFFFFF',
         headerBackgroundColorType: 'solid',
         firstHeaderBackgroundColor: '#4100E1',
         secondHeaderBackgroundColor: '#00B5FD',
         footerBackgroundColorType: 'solid',
         firstFooterBackgroundColor: '#4100E1',
         secondFooterBackgroundColor: '#00B5FD',
         headerGradientAngle: 145,
         footerGradientAngle: 145,
         scrollBarColor: '#D5D7DE',
         scrollBarWidth: 15,
         chatBlockBorderRadius: 10,
         chatBackgroundType: 'solid',
         firstChatBackgroundColor: '#FFFFFF',
         secondChatBackgroundColor: '#FFFFFF',
         chatBackgroundImageSrc: '/images/bot_hand.jpg',
         chatBackgroundImageTransparency: 0,
         speechBubbleFontType: 'Arial',
         speechBubbleFontSize: 14,
         userInputFontSize: 14,
         userFontColor: '#FFF',
         botFontColor: '#000000',
         avatarChatSize: 50,
         userAvatarIconSize: 22,
         userAvatarIconColor: '#FFF',
         userAvatarBorderColor: '#000000',
         userAvatarBackgroundColor: '#34a8fb',
         speechBubbleUserBackgroundColorType: 'solid',
         speechBubbleUserBackgroundFirstColor: '#4100E1',
         speechBubbleUserBackgroundSecondColor: '#00B5FD',
         speechBubbleBotBackgroundColorType: 'solid',
         speechBubbleBotBackgroundFirstColor: '#EFF2F8',
         speechBubbleBotBackgroundSecondColor: '#EFF2F8',
         speechBubbleBorderRadius: 5,
         messageText: 'Hello, how are you?',
         sendButtonIconColor: '#FFFFFF',
         sendButtonBackgroundColorType: 'solid',
         sendButtonBackgroundFirstColor: '#4100E1',
         sendButtonBackgroundSecondColor: '#00B5FD',
         sendLoadingDotsIconColor: '#A8D8E1',
         showChatImages: true,
         showPoweredBy: true,
         poweredByImageSrc: 'https://hosthubstoragetest.blob.core.windows.net/hosthub-blob/public/images/logo_white.png',
         datetimeColor: '#000000',
         enableOnlineMessage: true,
         userInputPlaceholderText: 'Typ hier uw bericht'
  };
  export const chatbotButtonSettings = {
    borderWidth: 3,
    borderColor: 'rgb(197 215 255)',
    buttonWidth: 70,
    buttonHeight: 70,
    buttonBorderRadius: 50,
    buttonBackgroundColor: '#5ACED9',
    buttonBackgroundColorB: '#2947A2',
    shadowWidth: 12,
    shadowColor: 'rgb(0 0 0 / 80%)',
    iconSize: 28,
    iconColor: '#fff',
    buttonSize: 70,
    showChatbuttonBubble: true,
    chatbuttonBubbleWidth: 280,
    chatButtonBubbleBackgroundColorType: 'solid',
    firstChatbuttonBubbleBackgroundColor: '#B0B0B0',
    secondChatbuttonBubbleBackgroundColor: '#B0B0B0',
    chatbuttonBubbleCloseBtnColor: '#BFBFBF',
    chatbuttonBubbleTextColor: '#fff',
    chatbuttonBubbleText: 'Welkom! Hoe kan ikk je helpen?',
    selectedBubbleEffect: 'none',
    chatbuttonBubbleDelay: 0,
    chatbuttonBubbleRightPosition: 15,
    chatbuttonBubbleTopPosition: 45
 }