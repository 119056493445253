<template>
  <v-container class="pt-5">
    <v-card flat>
      <v-row justify="center" class="pt-5">
        <v-col cols="12" xl="6" lg="10">
          <v-row>
            <v-text-field class="pl-3"
              :disabled="!selectedConfiguration._id"
              v-model="selectedConfiguration.name"
              :label="$t('configuration_name')"
              outlined
              dense
            ></v-text-field>
            <v-select class="pl-3"
              :disabled="!selectedConfiguration._id"
              v-model="selectedConfiguration.customerId"
              :items="customers"
              item-text="name"
              item-value="_id"
              :label="$t('customer')"
              outlined
              dense
              required
              :error="!selectedConfiguration.customerId"
            ></v-select>
            <v-tooltip v-if="!subscription.is_active && !subscription.end_date" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="deleteConfiguration">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{$t('delete_configuration')}}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!selectedConfiguration._id && (!hasConfigurationChanges && !hasSubscriptionChanges)" icon v-bind="attrs" v-on="on" @click="duplicateConfiguration">
                  <v-icon>mdi-content-duplicate</v-icon>
                </v-btn>
              </template>
              <span>{{$t('duplicate_configuration')}}</span>
            </v-tooltip>
          </v-row>
          <v-row>
            <v-col cols="10">
              <v-select
              :disabled="!selectedConfiguration._id && !is_upgrading || subscription.is_active && !is_upgrading"
              v-model="subscription.chosen_product"
              :items="subscriptions"
              item-text="name"
              item-value="value"
              :label="$t('subscription')"
              outlined
              dense
              @change="updateMessageLimit()"
              required
              :error="!subscription.chosen_product"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="is_upgrading" v-if="!selectedConfiguration.is_inactive" icon v-bind="attrs" v-on="on" @click="is_upgrading = true">
                    <v-icon>mdi-plus-circle-multiple-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('upgrade_subscription')}}</span>
              </v-tooltip>                
            </v-col>            
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col class="pt-6" cols="4">
                  <v-row>
                    <v-checkbox v-if="!subscription.is_active" dense
                      v-model="selectedConfiguration.is_inactive"
                      hide-details="true"
                      :label="$t('inactive')"
                    ></v-checkbox>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon class="pt-1 pl-2" v-if="!subscription.is_active" v-on="on" color="info">mdi-information-outline</v-icon>
                      </template>
                      <span>{{ $t('inactive_tt') }}</span> <!-- Replace 'active_tooltip' with your tooltip text -->
                    </v-tooltip>
                  </v-row>
                  <v-row>
                    <v-checkbox dense
                      :disabled="true"
                      v-model="subscription.is_active"
                      hide-details="true"
                      :label="$t('active_subscription')">
                    </v-checkbox>
                  </v-row>
                </v-col>
                <v-col cols="4" v-if="!subscription.is_active">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="!selectedConfiguration._id || subscription.is_active"
                        v-model="subscription.end_date"
                        :label="$t('trial_end_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :error="!subscription.end_date">
                      </v-text-field>
                    </template>
                    <v-date-picker :disabled="!selectedConfiguration._id || subscription.is_active" v-model="subscription.end_date" no-title @input="handleDateChange"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-btn @click="resetUsage()">{{$t('refresh_usage')}}</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-5">
              <v-row>
                <v-text-field
                  :disabled="!selectedConfiguration._id && !is_upgrading || subscription.is_active && !is_upgrading"
                  v-model="subscription.manual_amount"
                  :rules="[rules.numeric]"
                  :label="$t('amount_per_month')"
                  outlined
                  dense
                  @blur="formatAmount"
                ></v-text-field>
                <v-select class="pl-3 pr-3"
                  :disabled="!selectedConfiguration._id || subscription.is_active"
                  v-model="subscription.year_discount"
                  :items="discountOptions"
                  item-text="name"
                  item-value="value"
                  :label="$t('year_discount')"
                  :placeholder="$t('select_value')"
                  outlined
                  dense
                ></v-select>
              </v-row>
            </v-col>
            <v-row justify="center" class="pb-3">
              <v-col cols="2">
                <v-btn @click="saveChanges"
                  :disabled="!hasConfigurationChanges && !hasSubscriptionChanges"
                  color="primary"
                  >{{$t('save')}}
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn @click="cancelChanges"
                  :disabled="!hasConfigurationChanges && !hasSubscriptionChanges"
                  color="secondary"
                  >{{$t('cancel')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  import { EventBus } from '../../eventBus.js';
  import axios from 'axios';
  import Vue from 'vue';
    export default {
        name: 'GeneralTab',
        data: () => ({
          rules: {
            numeric: value => !isNaN(parseFloat(value)) && isFinite(value) || 'Must be numeric.'
          },
          subscription: {},
          initialSubscription: null,
          customers: [],
          subscriptions: [
            { name: 'Entry', value: 'entry' },
            { name: 'Basic', value: 'basic' },
            { name: 'Premium', value: 'premium' },
            { name: 'Professional', value: 'professional' }
          ],
          discountOptions: [
            { name: '0%', value: 0 },
            { name: '5%', value: 5 },
            { name: '10%', value: 10 },
            { name: '15%', value: 15 },
            { name: '20%', value: 20 },
            { name: '25%', value: 25 },
            { name: '30%', value: 30 }
          ],
          menu: false,
          initialConfiguration: null, 
          is_upgrading: false
        }),
        computed: {
          selectedConfiguration: {
            get() {
              // Return the Vuex state
              return this.$store.getters.selectedConfiguration;
            },
            set(value) {
              // Dispatch the action to update the Vuex state
              this.$store.dispatch('updateSelectedConfiguration', value);
            }
          },
          hasConfigurationChanges() {
            const result = JSON.stringify(this.selectedConfiguration) !== JSON.stringify(this.initialConfiguration);
            if (result) {
              EventBus.$emit('disable-tabs', true);
            }
            else {
              EventBus.$emit('disable-tabs', false);
            }
            return result;
          },
          hasSubscriptionChanges() {
            const result = JSON.stringify(this.subscription) !== JSON.stringify(this.initialSubscription);
            if (result) {
              EventBus.$emit('disable-tabs', true);
            }
            else {
              EventBus.$emit('disable-tabs', false);
            }
            return result;
          },
        },
        mounted() {
          this.initialize();
        },
        created() {
          // reinitialize upon configuration change
          EventBus.$on('set-configuration', () => {
            this.initialize();
          });
        },
        methods: {
          async initialize() {
            this.is_upgrading = false;
            EventBus.$emit('show-overlay');
            try {
                await Promise.all([
                  await this.getCustomers(),
                  await this.getSubscription()
                ]);
            } catch (error) {
                console.error("Error during initialization:", error);
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('initialization_error') });
            } finally {
                this.initialConfiguration = JSON.parse(JSON.stringify(this.selectedConfiguration));
                EventBus.$emit('hide-overlay');
            }
          },
          async deleteConfiguration() {
            try {
              // Retrieve the token
              const token = localStorage.getItem('userToken');
              if (!token) {
                console.error('User token not found');
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('user_not_authenticated') });
                return;
              }

              // Make the request with the Authorization header
              const response = await axios.post(`${this.$config.configurationServer}/configuration/delete`, {
                configurationId: this.selectedConfiguration._id
              }, {
                headers: { Authorization: `Bearer ${token}` }
              });

              // Handle response
              if (response.data.error) {
                // If the backend returned an error message, handle it here
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_delete_failed') });
              } else {
                // Configuration deleted successfully
                EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('configuration_delete_success') });
                // Clear the fields and refresh configurations
                this.$store.dispatch('updateSelectedConfiguration', {});
                EventBus.$emit('set-configuration', {});
                EventBus.$emit('load-configurations');
              }
            } catch (error) {
              // Handle any errors that occurred during the HTTP request
              console.error(error);
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_delete_failed') + error });
            }
          },
          async duplicateConfiguration() {
            EventBus.$emit('duplicate-configuration', this.selectedConfiguration);
          },
          async getCustomers() {
            const token = localStorage.getItem('userToken');
              if (token) {
                  return axios.get(this.$config.configurationServer + '/customers', {
                    headers: { Authorization: `Bearer ${token}` }
                  })
                  .then(configResponse => {
                  if (configResponse.status === 200) {
                    this.customers = configResponse.data;
                  } else {
                      EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('data_retrieval_unsucces') });
                      this.customers = [];
                  }
                  })
                  .catch(error => {
                    EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('data_retrieval_unsucces') + error });
                    this.customers = [];
                  });
              } else {
                  return Promise.resolve([]);
              }
          },
          async getSubscription() {
            const token = localStorage.getItem('userToken');
            const params = new URLSearchParams({
                  configuration_id: this.selectedConfiguration._id
                  }).toString();

            return axios.get(`${this.$config.configurationServer}/orders/get-subscription?${params}`, {
              headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
              if (response.status === 200) {
                this.subscription = response.data;
                this.initialSubscription = JSON.parse(JSON.stringify(this.subscription));
              } else {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('no_subscription_retrieved') });
              }
            })
            .catch(error => {
              console.log('subscription error: ', error);
              EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('no_subscription_retrieved')  });
              return null;
            })
          },
          async resetUsage() {
            EventBus.$emit('show-overlay');
            const token = localStorage.getItem('userToken');
            const configurationId = this.selectedConfiguration._id;

            return axios.post(`${this.$config.configurationServer}/configuration/reset_usage`, {
              configurationId: configurationId, // Correctly included in the request body
            }, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then(response => {
              EventBus.$emit('hide-overlay');
              if (response.status === 200) {
                EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('reset_usage_success') });
                EventBus.$emit('load-configuration', this.selectedConfiguration._id);
                EventBus.$emit('set-configuration', this.selectedConfiguration);
              } else {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('reset_usage_failed') });
              }
            })
            .catch(error => {
              EventBus.$emit('hide-overlay');
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('reset_usage_failed') + error });
            });
          },
          async setSubscription() {
            const token = localStorage.getItem('userToken');
            const configurationId = this.subscription.configuration_id;
            return axios.post(`${this.$config.configurationServer}/orders/update-subscription`, this.subscription, {
                headers: { Authorization: `Bearer ${token}` },
                params: { configuration_id: configurationId },
                // Customize which HTTP status codes should resolve the promise
                validateStatus: function (status) {
                    return (status >= 200 && status < 300) || status === 304; // Resolve only if status code is successful or 304
                }
            })
            .then(response => {
                if (response.status === 200) {
                    EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('subscription_update_success') });
                } else if (response.status === 304 || response.status === 204) { // Document not updated
                    EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('no_updates_were_made') });
                } else {
                    EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('subscription_update_fail') });
                }
            })
            .catch(error => {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('subscription_update_fail') + error });
            });
          },
          updateMessageLimit() {
            const product = this.subscription.chosen_product;
            switch (product) {
                case 'entry':
                    Vue.set(this.selectedConfiguration, 'message_limit', 50);
                    Vue.set(this.selectedConfiguration, 'functions', [{}]);
                    break;
                case 'basic':
                    Vue.set(this.selectedConfiguration, 'message_limit', 500);
                    break;
                case 'premium':
                    Vue.set(this.selectedConfiguration, 'message_limit', 1500);
                    break;
                case 'professional':
                    Vue.set(this.selectedConfiguration, 'message_limit', 5000);
                    break;
                case 'enterprise':
                    Vue.set(this.selectedConfiguration, 'message_limit', 0);
                    break;
                default:
                    console.log(`No message limit found for product: ${product}`);
                    break;
            }
          },
          // UI methods
          formatAmount() {
            if (this.subscription.manual_amount != null) {
              const amount = parseFloat(this.subscription.manual_amount);
              if (!isNaN(amount)) {
                this.subscription.manual_amount = amount.toFixed(2);
              }
            }
          },
          handleDateChange(date) {
            this.subscription.end_date = date;
            this.menu = false; // Close the menu after setting the date
          },
          cancelChanges() {
            if (this.hasConfigurationChanges) {
              this.selectedConfiguration = JSON.parse(JSON.stringify(this.initialConfiguration));
            }
            if (this.hasSubscriptionChanges) {
              this.subscription = JSON.parse(JSON.stringify(this.initialSubscription));
            }
            this.is_upgrading = false;
          },
          async saveChanges() {
            if (this.hasConfigurationChanges) {
              EventBus.$emit('save-configuration', this.selectedConfiguration);
            }
            if (this.hasSubscriptionChanges && !this.subscription.is_active) {
              await this.setSubscription();
              await this.getSubscription();              
            }
            this.is_upgrading = false;
          },
      }
  }
</script>

<style scoped>
</style>

