<template>
  <v-container class="pt-5">
    <v-card flat>
      <v-row class="pt-5">
        <v-col cols="12" xl="6" lg="10">
          <v-row>
            <v-col cols="2">
              <v-tabs vertical v-model="connectionsTab" background-color="white" color="primary">
                <v-tab key="email">E-mail</v-tab>
                <v-tab key="twilio">Twilio</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="10">
              <v-tabs-items v-model="connectionsTab">
                <v-tab-item key="email">
                  <v-row no-gutters >
                    <v-col cols="12">
                      <v-checkbox dense
                        v-model="selectedConfiguration.use_email"
                        hide-details="true"
                        :label="$t('use_email_channel')">
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <template v-if="selectedConfiguration.use_email">
                        <v-radio-group v-model="emailChannelConfiguration.useOutlookConsent" row>
                          <v-radio :label="$t('use_outlook_consent')" :value="true"></v-radio>
                          <v-radio :label="$t('use_imap')" :value="false"></v-radio>
                        </v-radio-group>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="emailChannelConfiguration.useOutlookConsent">
                    <v-col cols="12">
                      <template>
                        <v-radio-group v-model="emailChannelConfiguration.sendEmail" row>
                          <v-radio :label="$t('use_send_email')" :value="true"></v-radio>
                          <v-radio :label="$t('use_draft_only')" :value="false"></v-radio>
                        </v-radio-group>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="selectedConfiguration.use_email">
                    <v-col cols="4">
                      <v-text-field dense
                        v-model="emailChannelConfiguration.email_user"
                        outlined
                        :label="$t('email_user')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pl-3 pr-3" v-if="!emailChannelConfiguration.useOutlookConsent">
                      <v-text-field
                        dense
                        v-model="emailChannelConfiguration.email_password"
                        outlined
                        type="password"
                        :label="$t('email_password')"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="4">
                      <v-text-field dense
                        v-model="emailChannelConfiguration.authorized_sender"
                        outlined
                        :label="$t('authorized_sender')"
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="4" v-if="!emailChannelConfiguration.useOutlookConsent">
                      <v-text-field dense
                        v-model="emailChannelConfiguration.email_server"
                        outlined
                        :label="$t('imap_server')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="selectedConfiguration.use_email">
                    <v-col cols="12">
                      <v-row>
                      <v-btn @click="testImap"
                          v-if="emailChannelConfiguration._id && !emailChannelConfiguration.useOutlookConsent"
                          :disabled="!emailChannelConfiguration.email_user || !emailChannelConfiguration.email_password || !emailChannelConfiguration.email_server || hasEmailChannelConfigurationChanges">
                          {{$t('test_imap')}}
                        </v-btn>
                        <v-btn @click="generateConsentLink"
                          v-if="emailChannelConfiguration.useOutlookConsent"
                          :disabled="!emailChannelConfiguration.email_user">
                          {{$t('generate_consent_link')}}
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pt-5" justify="center">
                    <v-col cols="10">
                      <v-text-field dense
                        disabled
                        v-if="consentUrl && emailChannelConfiguration.useOutlookConsent"
                        v-model="consentUrl"
                        outlined
                        :label="$t('consent_url')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="pl-2">
                      <v-btn v-if="consentUrl" icon small @click="copyConsentUrlToClipboard">
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="twilio">
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-checkbox dense
                        v-model="selectedConfiguration.use_twilio"
                        hide-details="true"
                        :label="$t('use_twilio_channel')">
                      </v-checkbox>
                    </v-row>
                    <v-row no-gutters class="pt-3">
                      <v-col cols="4">
                        <v-text-field dense
                          v-if="selectedConfiguration.use_twilio"
                          v-model="selectedConfiguration.whatsapp_address"
                          outlined
                          :label="$t('whatsapp_address')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-btn v-if="selectedConfiguration.whatsapp_address" @click="deleteTwilioConversations">
                        {{$t('reset_twilio_conversations')}}
                      </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field dense
                          v-model="sid"
                          outlined
                          :label="$t('sid address')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-btn @click="testsid">test SID</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>
                <v-row justify="center" class="pt-5 pr-2">
                  <v-col cols="2">
                    <v-btn @click="saveChanges"
                      :disabled="!hasConfigurationChanges && !hasEmailChannelConfigurationChanges"
                      color="primary"
                      >{{$t('save')}}
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn @click="cancelChanges"
                      :disabled="!hasConfigurationChanges && !hasEmailChannelConfigurationChanges"
                      color="secondary"
                      >{{$t('cancel')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
    import { EventBus } from '../../eventBus.js';
    import axios from 'axios';
    export default {
        name: 'ConnectionsTab',
        data: () => ({
          initialConfiguration: null,
          connectionsTab: null,
          initialEmailChannelConfiguration: null,
          emailChannelConfiguration: {
            selectedOption: ''
          },
          emailOptions: [
            { text: 'use_imap', value: 'use_imap' },
            { text: 'use_outlook_consent', value: 'use_outlook_consent' },
          ],
          consentUrl: null,
          sid: ''
        }),
        computed: {
          selectedConfiguration: {
            get() {
              return this.$store.getters.selectedConfiguration;
            },
            set(value) {
              this.$store.dispatch('updateSelectedConfiguration', value);
            }
          },
          hasConfigurationChanges() {
            const result = JSON.stringify(this.selectedConfiguration) !== JSON.stringify(this.initialConfiguration);
            if (result) {
              EventBus.$emit('disable-tabs', true);
            }
            else {
              EventBus.$emit('disable-tabs', false);
            }
            return result;
          },
          hasEmailChannelConfigurationChanges() {
            const result = JSON.stringify(this.emailChannelConfiguration) !== JSON.stringify(this.initialEmailChannelConfiguration);
            if (result) {
              EventBus.$emit('disable-tabs', true);
            }
            else {
              EventBus.$emit('disable-tabs', false);
            }
            return result;
          },
          translatedEmailOptions() {
            return this.emailOptions.map(option => ({
              ...option,
              text: this.$t(option.text)
            }));
          }
        },
        mounted() {
            this.initialize();
        },
        created() {
          EventBus.$on('set-configuration', () => {
            this.initialize();
          });
        },
        methods: {
          async initialize() {
            this.consentUrl = '';
            EventBus.$emit('show-overlay');
            try {
              await this.getEmailChannelConfiguration();
            } catch (error) {
              console.error("Error during initialization:", error);
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('initialization_error') });
            } finally {
              this.initialConfiguration = JSON.parse(JSON.stringify(this.selectedConfiguration));
              EventBus.$emit('hide-overlay');
            }

          },
          cancelChanges() {
            if (this.hasConfigurationChanges) {
              this.selectedConfiguration = JSON.parse(JSON.stringify(this.initialConfiguration));
            }
            if(this.hasEmailChannelConfigurationChanges) {
              this.emailChannelConfiguration = JSON.parse(JSON.stringify(this.initialEmailChannelConfiguration));
            }
          },
          async saveChanges() {
            if (this.hasConfigurationChanges) {
              EventBus.$emit('save-configuration', this.selectedConfiguration);
            }
            if (this.hasEmailChannelConfigurationChanges) {
              await this.saveEmailChannelConfigurationChanges();
              await this.getEmailChannelConfiguration();
            }
          },
          async getEmailChannelConfiguration() {
            const token = localStorage.getItem('userToken');
            const params = new URLSearchParams({
              configuration_id: this.selectedConfiguration._id
            }).toString();

            return axios.get(`${this.$config.configurationServer}/configuration/get_email_channel_config?${params}`, {
              headers: { Authorization: `Bearer ${token}` },
              // Treat a status code in the range 200-299 as success
              // Also treat 404 as success for this request
              validateStatus: function (status) {
                return (status >= 200 && status < 300) || status === 404; // Resolve only if the status code is less than 500
              }
            })
            .then(response => {
              if (response.status === 404) {
                // Handle not found: create a new object in memory
                this.emailChannelConfiguration =
                  {
                    configuration_id: this.selectedConfiguration._id,
                    useOutlookConsent: true
                  };
                this.initialEmailChannelConfiguration = JSON.parse(JSON.stringify(this.emailChannelConfiguration));
                console.log('*** No config found, creating a new object');
              } else {
                // Handle normal success response
                this.emailChannelConfiguration = response.data;
                this.initialEmailChannelConfiguration = JSON.parse(JSON.stringify(this.emailChannelConfiguration));
                console.log('*** Email config: ', response.data);
              }
            })
            .catch(error => {
              // This catch will now only be triggered for status codes outside the 200-299 & not 404
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('emailchannel_unsucces') + error });
              return null;
            });
          },
          async saveEmailChannelConfigurationChanges() {
            const token = localStorage.getItem('userToken');
            this.emailChannelConfiguration.configuration_id = this.selectedConfiguration._id;
            return axios.post(`${this.$config.configurationServer}/configuration/create_or_update_email_channel_config`, this.emailChannelConfiguration, {
                headers: { Authorization: `Bearer ${token}` },
                // Customize which HTTP status codes should resolve the promise
                validateStatus: function (status) {
                    return (status >= 200 && status < 300) || status === 304; // Resolve only if status code is successful or 304
                }
            })
            .then(response => {
                if (response.status === 200) {
                    EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('subscription_update_success') });
                } else if (response.status === 304 || response.status === 204) { // Document not updated
                    EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('no_updates_were_made') });
                } else {
                    EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('subscription_update_fail') });
                }
            })
            .catch(error => {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('subscription_update_fail') + error });
            });
          },
          async testImap() {
            EventBus.$emit('show-overlay');
            const token = localStorage.getItem('userToken');
            try {
              const response = await axios.get(`${this.$config.configurationServer}/configuration/test_email_channel_config`, {
                params: { configuration_id: this.selectedConfiguration._id },
                headers: { Authorization: `Bearer ${token}` },
              });

              // If there's a message in the response, it could be a success or an error message.
              if (response.data.message) {
                if (response.data.message.includes("Error:")) {
                  // If the message includes "Error:", treat it as an error.
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: response.data.message });
                } else {
                  // Otherwise, it's a success message.
                  EventBus.$emit('show-snackbar', { type: 'INFO', message: response.data.message });
                }
              } else if (response.data.error) {
                // If there's an explicit "error" field, display it as an error.
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: response.data.error });
              } else {
                // If none of the above, it's an unexpected situation.
                EventBus.$emit('show-snackbar', { type: 'WARNING', message: this.$t('imap_test_failed') });
              }
            } catch (error) {
              // Handle network errors or other unexpected errors
              let errorMessage = this.$t('imap_test_error');
              if (error.response && error.response.data && error.response.data.error) {
                // Specific error message from the backend
                errorMessage += ': ' + error.response.data.error;
              } else {
                // Generic error handling
                errorMessage += ': ' + error.toString();
              }
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: errorMessage });
            } finally {
              EventBus.$emit('hide-overlay');
            }
          },
          generateConsentLink() {
            const clientId = this.$config.emailHandlerClientId;
            const configurationServer = this.$config.configurationServer;
            const redirectUri = encodeURIComponent(`${configurationServer}/consents/email`);
            const scopes = [
              'https://graph.microsoft.com/Mail.ReadWrite',
              'https://graph.microsoft.com/User.Read',
              'offline_access'
            ];

            if (this.emailChannelConfiguration && this.emailChannelConfiguration.sendEmail) {
              scopes.push('https://graph.microsoft.com/Mail.Send');
            }

            const scope = encodeURIComponent(scopes.join(' '));
            const tenant = 'common';
            const configurationId = this.selectedConfiguration._id;
            const state = encodeURIComponent(configurationId);
            this.consentUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&response_mode=query&prompt=consent`;
          },
          copyConsentUrlToClipboard() {
            if (navigator.clipboard && window.isSecureContext) {
              // Navigator clipboard is available and in a secure context
              navigator.clipboard.writeText(this.consentUrl).then(() => {
                // Clipboard successfully set
                EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('value_copied_to_clipboard') });
              }, () => {
                // Clipboard write failed, handle error
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error_copying_to_clipboard') });
              });
            }
          },
          async deleteTwilioConversations() {
              const token = localStorage.getItem('userToken');
              return axios.post(`${this.$config.configurationServer}/chatoverview/delete_twilio_conversations`, {}, {
                  headers: { Authorization: `Bearer ${token}` },
                  // Customize which HTTP status codes should resolve the promise
                  validateStatus: function (status) {
                      return (status >= 200 && status < 300) || status === 304; // Resolve only if status code is successful or 304
                  }
              })
              .then(response => {
                  if (response.status === 200) {
                      EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('delete_success') });
                  } else if (response.status === 304 || response.status === 204) { // No updates were made
                      EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('no_conversations_deleted') });
                  } else {
                      EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('delete_fail') });
                  }
              })
              .catch(error => {
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('delete_fail') + error });
              });
          },
          async testsid() {


              const token = localStorage.getItem('userToken');

              const response = await axios.get(`${this.$config.configurationServer}/chatoverview/test_sid`, {
                params: { sid_id: this.sid },
                headers: { Authorization: `Bearer ${token}` },
              });
              console.log('*** sid test:', response);
          }
        }
    }
</script>

<style scoped>
</style>

