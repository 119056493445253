<template>
  <v-card class="window-card">
    <div class="chart-title">ADMIN - Gemiddelde Rating per Configuratie</div>
    <apex-chart :options="options" :series="series"></apex-chart>
  </v-card>
</template>

<script>
import axios from 'axios';
import { EventBus } from './../../eventBus.js';

export default {
name: 'AdminConfigurationsAverageRatingWidget',
data() {
  return {
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        min: 1,
        max: 5,
        title: {
          text: this.$t('average_rating')
        }
      },
      plotOptions: {
        bar: {
          distributed: true  // Enables individual color settings for each bar
        }
      },
      colors: [], // Placeholder for dynamically setting colors
    },
    series: [{
      name: this.$t('average_rating'),
      data: []
    }]
  };
},
created() {
  EventBus.$on('reload-chart', this.loadData);
},
beforeDestroy() {
  EventBus.$off('reload-chart', this.loadData);
},
mounted() {
  this.loadData();
},
methods: {
  getRatingColor(rating) {
    let r, g;
    if (rating < 3) {
      // From red to orange (rating 1 to 3)
      r = 255;
      g = Math.round(127 * ((rating - 1) / 2)); // Gradually increase green up to halfway
    } else {
      // From orange to green (rating 3 to 5)
      r = Math.round(255 * ((5 - rating) / 2)); // Gradually decrease red from halfway
      g = 255;
    }
    // Set color to grey for ratings of 0 to handle the edge case
    if (rating === 0) {
      return `rgb(128, 128, 128)`; // Grey color
    }
    return `rgb(${r}, ${g}, 0)`; // Keeping blue at 0 to avoid brown tones
  },
  loadData() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      console.error("No token found");
      return;
    }
    const widget_id = 'widget_adm_3';
    axios.get(this.$config.configurationServer + '/dashboard/get_widget_data', {
      headers: { Authorization: `Bearer ${token}` },
      params: { widget_id }
    })
    .then(response => {
      if (response.status === 200 && Array.isArray(response.data)) {
        this.options = {
          ...this.options,
          xaxis: {
            ...this.options.xaxis,
            categories: response.data.map(item => item.configuration_name)
          },
          colors: response.data.map(item => this.getRatingColor(item.average_rating)) // Set colors based on ratings
        };
        this.series = [{
          ...this.series[0],
          data: response.data.map(item => item.average_rating)
        }];
      } else {
        console.error('Error loading data:', response);
      }
    })
    .catch(error => {
      EventBus.$emit('show-snackbar', {
        type: 'ERROR',
        message: 'Data retrieval unsuccessful: ' + error.message
      });
      console.error('Error fetching widget data:', error);
    });
  }
}
}
</script>

<style scoped>
.window-card {}

.chart-title {
text-align: center;
font-size: 24px;
margin-bottom: 20px;
}
</style>
