<template>
  <v-container> 
    <div v-if="showConfigDashboard"> <!-- Container for selected configuration -->
      <!-- First Row: Conversations and Ratings -->
      <v-row class="text-center pt-5">
        <v-col cols="12" md="6">
          <v-card class="window-card">
            <div class="chart-title">CONFIG - Monthly Conversations Overview</div>
            <apex-chart :options="conversationChartOptions" :series="conversationSeries"></apex-chart>
          </v-card>
        </v-col>       
      </v-row>
    </div>
    <div v-if="isAdmin && !showConfigDashboard">
      <v-row>
        <v-col cols="6">
          <admin-conversations-widget></admin-conversations-widget>
        </v-col>
        <v-col cols="6">
          <admin-most-conversations-per-customer-widget></admin-most-conversations-per-customer-widget>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <admin-average-rating-per-configuration-widget></admin-average-rating-per-configuration-widget>
        </v-col>
        <v-col cols="6">
          <admin-average-messages-and-costs-per-configuration-widget></admin-average-messages-and-costs-per-configuration-widget>
        </v-col>
      </v-row>
    </div>
  </v-container> 
</template>

<script>
import { EventBus } from './../eventBus.js';
import AdminConversationsWidget from './widgets/adminConverstationsWidget.vue'
import AdminMostConversationsPerCustomerWidget from './widgets/adminMostConversationsPerCustomerWidget.vue';
import AdminAverageRatingPerConfigurationWidget from './widgets/adminAverageRatingPerConfigurationWidget.vue';
import AdminAverageMessagesAndCostsPerConfigurationWidget from './widgets/adminAverageMessagesAndCostsPerConfigurationWidget.vue';
// import axios from 'axios';

export default {
  name: 'DashboardTab',
  components: {
    AdminConversationsWidget,
    AdminMostConversationsPerCustomerWidget,
    AdminAverageRatingPerConfigurationWidget, 
    AdminAverageMessagesAndCostsPerConfigurationWidget
  },
  data() {
    return {
      showConfigDashboard: false, 
      conversationChartOptions: {
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        xaxis: {
          categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
        }
      },
      conversationSeries: [
        {
          name: 'Chatbot',
          data: [6, 16, 18, 8, 6, 5, 7, 25, 33, 12, 34, 45, 24, 26, 13, 26, 12, 7, 10, 12, 8, 4, 23, 14, 12, 11, 6, 16, 13, 26]
        },
        {
          name: 'WhatsApp',
          data: [5, 15, 17, 7, 5, 4, 6, 24, 32, 11, 33, 44, 23, 25, 12, 25, 11, 6, 9, 11, 7, 3, 22, 13, 11, 10, 5, 15, 12, 25]
        },
        {
          name: 'Facebook',
          data: [4, 14, 16, 6, 4, 3, 5, 23, 31, 10, 31, 43, 21, 23, 10, 23, 9, 5, 8, 9, 5, 2, 21, 12, 9, 8, 4, 14, 10, 23]
        },
        {
          name: 'Email',
          data: [7, 17, 19, 9, 7, 6, 8, 26, 34, 13, 35, 46, 25, 27, 14, 27, 13, 8, 11, 13, 9, 5, 24, 15, 13, 12, 7, 17, 14, 27]
        }
      ],     
    };
  },
  computed: {    
    isAdmin() {
      return this.$store.state.userRole === 'admin';
    },
  },
  created() {
    EventBus.$on('reload-dashboard', (configurationId) => {
      if (configurationId) {
        this.showConfigDashboard = true;
      }
      else {
        this.showConfigDashboard = false;
      }
    });
  }
}
</script>

<style>
.chart-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
</style>