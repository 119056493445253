<template>
    <v-dialog v-model="localDialog" persistent max-width="600px">
        <v-card class="dialog-card">
            <v-row justify="center">
                <img class="logo" src="../../assets/logo_color.png">
                <v-btn icon @click="close" class="close-button">
                    <v-icon size="36">mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-card-text class="dialog-content mobile-zoom" style="padding-top:15px">
                <div class="subscription-header">                  
                    <h1 class="purple-text h1-class" style="padding:15px; font-size: 36px;">
                      <span v-if="chosenProduct === 'entry'">{{$t('subscription_products.entry')}}</span>
                      <span v-if="chosenProduct === 'basic'">{{$t('subscription_products.basic')}}</span>
                      <span v-if="chosenProduct === 'premium'">{{$t('subscription_products.premium')}}</span>
                      <span v-if="chosenProduct === 'professional'">{{$t('subscription_products.professional')}}</span>
                      <span v-if="chosenProduct === 'enterprise'">{{$t('subscription_products.enterprise')}}</span>
                    </h1>                   
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <h1 class="price" style="padding-top:20px;">
                            <span class="h1-class" v-if="switchValueSubscriptionType == 'monthly'"><strong>{{ formatCurrency(rate) }}</strong></span>
                            <span class="h1-class" v-if="switchValueSubscriptionType == 'annual'"><strong>{{ formatCurrency(rate/12) }}</strong></span>
                            <span class="pl-2 h3-class">{{$t('per_month')}}</span>                         
                        </h1>                     
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <span class="pt-5" v-if="switchValueSubscriptionType == 'monthly'">{{$t('monthly')}}</span>
                        <span class="pt-5" v-if="switchValueSubscriptionType == 'annual'">{{$t('annual')}}</span>
                        <v-switch class="pl-3"
                            @change="setSubscriptionType"
                            inset
                        ></v-switch>
                        <span class="pt-5">{{manualYearDiscountPercentage}}% {{$t('discount')}}</span>                        
                      </v-col>
                    </v-row>
                </div>
                <div class="pricing-details">
                    <div v-if="switchValueSubscriptionType == 'annual'">
                        <div class="pricing-item">
                            <div>
                                <span class="purple-text" v-if="chosenProduct === 'entry'">{{$t('subscription_products.entry')}}</span>
                                <span class="purple-text" v-if="chosenProduct === 'basic'">{{$t('subscription_products.basic')}}</span>
                                <span class="purple-text" v-if="chosenProduct === 'premium'">{{$t('subscription_products.premium')}}</span>
                                <span class="purple-text" v-if="chosenProduct === 'professional'">{{$t('subscription_products.professional')}}</span>
                                <span class="purple-text" v-if="chosenProduct === 'enterprise'">{{$t('subscription_products.enterprise')}}</span>
                                <br/>
                                <span>{{$t('annual_amount')}} </span>                                                         
                            </div>
                            <div style="display: flex; align-items: end;">
                                <span class="price"><strong>{{ formatCurrency(fullYearRate) }}</strong></span>
                            </div>
                        </div>
                        <div class="pricing-item" style="padding-top:5px;">
                            <div>
                                <span>{{$t('discount')}}</span>
                            </div>
                            <div style="display: flex; align-items: end;">
                                <span class="price"><strong>- {{ formatCurrency(discountAmount) }}</strong></span>
                            </div>
                        </div>
                        <div class="pricing-item" style="padding-top:5px;">
                            <div>
                              <span>{{$t('billed_annually')}} </span>     
                            </div>
                            <div style="display: flex; align-items: end;">
                                <span class="price"><strong>{{ formatCurrency(rate) }}</strong></span>
                            </div>
                        </div>
                        <v-divider style="width: 95%; margin-left: auto; margin-right: auto;"></v-divider>                                             
                        <div class="pricing-item">
                            <span><strong>{{$t('subtotal')}}</strong></span>
                            <span class="price"><strong>{{ formatCurrency(rate) }}</strong></span>
                        </div>
                        <div class="pricing-item" style="padding-top: 10px;">
                            <span>{{$t('vat_21')}}:</span>
                            <span class="price">{{ formatCurrency(rateTax) }}</span>
                        </div>
                        <v-divider style="width: 95%; margin-left: auto; margin-right: auto;"></v-divider>
                        <div class="pricing-item">
                            <span><strong>{{$t('total_to_pay')}}:</strong></span>
                            <span class="price"><strong>{{ formatCurrency(rate + rateTax) }}</strong></span>
                        </div>
                    </div>

                    <div v-if="switchValueSubscriptionType == 'monthly'">
                        <div class="pricing-item">
                            <div>
                                <span class="purple-text" v-if="chosenProduct === 'entry'">{{$t('subscription_products.entry')}}</span>
                                <span class="purple-text" v-if="chosenProduct === 'basic'">{{$t('subscription_products.basic')}}</span>
                                <span class="purple-text" v-if="chosenProduct === 'premium'">{{$t('subscription_products.premium')}}</span>
                                <span class="purple-text" v-if="chosenProduct === 'professional'">{{$t('subscription_products.professional')}}</span>
                                <span class="purple-text" v-if="chosenProduct === 'enterprise'">{{$t('subscription_products.enterprise')}}</span>
                                
                                <br/>
                                <span>{{$t('billed_monthly')}}</span>
                            </div>
                            <div style="display: flex; align-items: end;">
                                <span class="price"><strong>{{ formatCurrency(rate) }}</strong></span>
                            </div>
                        </div>
                        <v-divider style="width: 95%; margin-left: auto; margin-right: auto;"></v-divider>                       
                        <div class="pricing-item">
                            <span><strong>{{$t('subtotal')}}</strong></span>
                            <span class="price"><strong>{{ formatCurrency(rate) }}</strong></span>
                        </div>
                        <div class="pricing-item" style="padding-top: 10px;">
                            <span>{{$t('vat_21')}}</span>
                            <span class="price">{{ formatCurrency(rateTax) }}</span>
                        </div>
                        <v-divider style="width: 95%; margin-left: auto; margin-right: auto;"></v-divider>
                        <div class="pricing-item">
                            <span><strong>{{$t('total_to_pay')}}</strong></span>
                            <span class="price"><strong>{{ formatCurrency(rate + rateTax) }}</strong></span>
                        </div>
                    </div>
                </div>
                <div class="terms-and-conditions text-center" style="display:flex; align-items: center; justify-content: center;">
                    <v-checkbox color="#E140A0" v-model="consented"></v-checkbox>
                    <span v-html="$t('i_agree_with_the_service_conditions')"></span>
                </div>
            </v-card-text>
            <v-card-actions class="card-actions text-center mobile-zoom" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                <v-btn :disabled="!consented" @click="initiateOrder" elevation="5" class="upgrade-btn" color="#E140A0" style="height:60px;">
                    <span style="color:white;">{{$t('pay_and_subscribe')}}</span>
                </v-btn>
                <span class="confirmation-text" style="text-align: center; margin-top: 20px;">
                    {{$t('by_confirming_your_subscription')}}
                </span>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from 'axios';
import { EventBus } from '../../eventBus.js';
  export default {
    props: {
        dialog: Boolean,
        configurationId: String,
        chosenProduct: String,
        chosenSubscriptionType: String, 
        manualAmount: {
          type: Number,
          default: null, // Set default value to null in case of auto onboarding
        },
        manualYearDiscountPercentage: {
          type: Number,
          default: null, // Set default value to null in case of auto onboarding
        },
    },
    data() {
      return {
        localDialog: this.dialog,        
        proratedPrice: 0,
        rate: 0,
        rateTax: 0,
        calculatedProratedPriceTax: 0,
        consented: false, 
        switchValueSubscriptionType: '',
        discountAmount: 0, 
        fullYearRate: 0
      };
    },
    watch: {
      dialog(newVal) {
        this.localDialog = newVal;
        this.switchValueSubscriptionType = this.chosenSubscriptionType;
        this.getCalculatedSubscriptionPrice();
      },
      localDialog(newVal) {
        if (!newVal) {
          this.$emit('update:dialog', newVal);
        }
      },
    },
    mounted(){        
    },

    methods: {
      close() {
        this.localDialog = false;
      },
        async initiateOrder() {
            EventBus.$emit('show-overlay');
            try {
                const orderData = {
                    subscriptionType: this.switchValueSubscriptionType,
                    chosenProduct: this.chosenProduct,
                    configurationId: this.configurationId
                };
                const token = localStorage.getItem('userToken');

                const response = await axios.post(`${this.$config.configurationServer}/orders/create_customer_subscription`, orderData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
                });

                // Handle the response. Assuming the backend sends back the URL to redirect for payment.
                if (response && response.data && response.data.paymentUrl) {
                  // Redirect the user to the payment URL
                  console.log('*** response.data: ', response.data)          
                  window.open(response.data.paymentUrl.paymentUrl, '_blank');               
                  const session_id = response.data.paymentUrl.session_id;
                  await this.pollPaymentStatus(session_id);
                } else {                  
                  EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('order_unsucces') + response });
                  console.error('Unexpected response format', response);                  
                }
            } catch (error) {                
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('order_unsucces') + error });
                console.error('Error creating order:', error);
                
            } finally {
                // Hide the overlay or loading indicator
                //EventBus.$emit('hide-overlay');
            }
        },
        async pollPaymentStatus(session_id) {
          const maxAttempts = 60;
          let attempts = 0;

          const poll = async () => {
              try {
                  const statusResponse = await axios.get(`${this.$config.configurationServer}/stripe/validate_stripe_session?session_id=${session_id}`);
                  const paymentStatus = statusResponse.data && statusResponse.data.status;
                  switch (paymentStatus) {
                      case 'succeeded':
                          // Handle successful payment
                          console.log('**** succeeded');
                          EventBus.$emit('hide-overlay');
                          EventBus.$emit('show-promosnackbar', {
                              message: this.$t('your_payment_succeeded'),
                              header: this.$t('thank_you'), 
                              hideButtons: true
                          });
                          EventBus.$emit('load-configurations');
                          this.close();
                          break;
                      case 'requires_payment_method':
                          // Handle payment that needs a new payment method
                          EventBus.$emit('show-snackbar', {
                              type: 'WARN', 
                              message: this.$t('your_payment_requires_a_new_payment_method'), 
                              timeout: -1
                          });
                          EventBus.$emit('hide-overlay');
                          this.close();
                          break;
                      case 'canceled':
                          // Handle payment canceled
                          EventBus.$emit('show-snackbar', {
                              type: 'WARN', 
                              message: this.$t('your_payment_canceled'), 
                              timeout: -1
                          });
                          EventBus.$emit('hide-overlay');
                          this.close();
                          break;
                      default:
                          if (attempts < maxAttempts) {
                              // If not paid and attempts limit not reached, poll again
                              attempts++;
                              setTimeout(poll, 5000); // Poll every 2 seconds (adjust as needed)
                          } else {
                              // Max attempts reached, handle accordingly
                              this.close();
                              EventBus.$emit('hide-overlay');
                              EventBus.$emit('show-snackbar', {
                                  type: 'ERROR', 
                                  message: this.$t('no_payment_update_received_in_time'), 
                                  timeout: -1
                              });
                          }
                          break;
                  }
              } catch (error) {
                  console.error('Error polling payment status:', error);
                  this.close();
                  EventBus.$emit('hide-overlay');
                  EventBus.$.emit('show-snackbar', {
                      type: 'ERROR', 
                      message: this.$t('no_payment_update_received'), 
                      timeout: -1
                  });
              }
          };
          poll();
        },

        async getCalculatedSubscriptionPrice() {
          
            EventBus.$emit('show-overlay');

            try {
                const request = {
                    chosenProduct: this.chosenProduct,
                    subscriptionType: this.switchValueSubscriptionType, 
                    manualAmount: this.manualAmount,
                    manualYearDiscountPercentage: this.manualYearDiscountPercentage
                };

                const token = localStorage.getItem('userToken');

                const response = await axios.post(`${this.$config.configurationServer}/orders/calculate_subscription_price`, request, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
                });

                if (response && response.data) {                    
                    this.proratedPrice = response.data.calculated_prorated_price;
                    this.rate = response.data.rate;
                    this.rateTax = response.data.rate_tax;
                    this.calculatedProratedPriceTax = response.data.calculated_prorated_price_tax;
                    this.discountAmount = response.data.discount_amount;
                    this.fullYearRate = response.data.full_year_rate;
                    
                } else {
                    EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('proratedprice_unsucces') + response });
                    console.error('Unexpected response format', response);
                }
            } catch (error) {

                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('proratedprice_unsucces') + error });
                console.error('Error calculated prorated price:', error);
                this.close();
            } finally {
                EventBus.$emit('hide-overlay');
            }
        }, 
        setSubscriptionType() {
          if (this.switchValueSubscriptionType == 'annual') {
            this.switchValueSubscriptionType = 'monthly';
          }
          else {
            this.switchValueSubscriptionType = 'annual';
          }
          this.getCalculatedSubscriptionPrice();
        },
        formatCurrency(value) {
            // Determine if we have a whole number or not
            const isWholeNumber = Number.isInteger(value);

            // Create a formatter for the numeric part. This time, ensure we get the thousands separator.
            const formatter = new Intl.NumberFormat('de-DE', {
              minimumFractionDigits: isWholeNumber ? 0 : 2, // Avoid .00 for whole numbers
            });

            let formattedValue = formatter.format(value);

            // For whole numbers, append ',-'. Otherwise, ensure decimal places are correctly formatted.
            formattedValue = isWholeNumber ? `${formattedValue},-` : formattedValue.replace(/,00$/, ',-');

            // Prepend € symbol manually to ensure it's always at the start
            return `€${formattedValue}`;
          }
    },
  };
  </script>

<style scoped>

.h1-class {
    font-size: 36px;
  }
  .h2-class {
    font-size: 30px;
  }
  .p-class {
    font-size: 20px;
  }

.dialog-card {
  overflow: hidden;
  border-radius: 25px;
}

.logo {
  width: 250px;
  margin-top: 25px; /* Adjust as needed */
}

.close-button {
  position: absolute;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
}

.subscription-header {
  text-align: center;
  margin-bottom: 20px;
}

.subscription-title {
  display: block; /* Makes it take up the full width */
  text-align: center;
  margin-bottom: 5px;
}

.pricing-details {
  margin-bottom: 20px;
}

/* Dialog Content Styling */
.dialog-content {
  font-family: 'Arial', sans-serif;
  color: #333; /* Adjust the base text color as needed */
  margin: 0px;
}
.pricing-item {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
}
.pricing-item span {
    font-size:20px;
}

/* Typography */
.purple-text {
  color: #800080;
  font-weight: bold;
  font-size: 28px; /* Adjust as needed for your design */
}

.text-center {
  text-align: center;
}

/* Divider */
.v-divider {
  margin: 10px 0;
  border-bottom: 1px solid #ccc; /* Adjust divider color as needed */
}

/* Subscription Info */
.subscription-info {
  margin-bottom: 20px;
}

.subscription-info div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.subscription-info span {
  flex: 1;
  text-align: center;
}

/* Button Styling */
.upgrade-btn {
  border-radius: 15px;
  color: white;
  font-weight: bolder;
  background-color: #E140A0;
  padding: 10px 20px; /* Adjust padding as needed */
  margin: 20px auto; /* Center button in the available space */
  display: block; /* Make it a block element to apply margin auto */
  text-transform: uppercase; /* Optional: makes text uppercase */
}

.upgrade-btn span {
    color:white;
}

.upgrade-btn:hover {
  background-color: #C13584; /* Darker shade for hover effect */
}

/* Checkbox */
.v-checkbox {
  margin-top: 20px;
}

/* Terms and Conditions Text */
.terms-text {
  font-size: 14px; /* Adjust the font size as needed */
  color: #666; /* Adjust the font color as needed */
  text-align: center;
  margin-top: 10px;
}

/* Small print at the bottom */
.small-print {
  font-size: 12px; /* Adjust as needed */
  color: #666; /* Adjust as needed */
  text-align: center;
  margin-top: 20px;
}

/* Ensure the button and links are accessible */
a, button {
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 600px) {
  .dialog-content {
    margin: 10px;
  }

  .pricing-card {
    padding-bottom: 50px;
  }

  /* Adjust font sizes for smaller screens */
  .purple-text {
    font-size: 24px;
  }

  .terms-text, .small-print {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .mobile-zoom{
    zoom: 90%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 85%
  }
}

@media (max-width: 550px) {
  .mobile-zoom{
    zoom: 80%
  }
}

@media (max-width: 500px) {
  .mobile-zoom{
    zoom: 75%
  }
}

@media (max-width: 450px) {
  .mobile-zoom{
    zoom: 70%
  }
}

@media (max-width: 400px) {
  .mobile-zoom{
    zoom: 65%
  }
}

.dialog-card span{
    color:black;
}


</style>
