<template>
  <v-container class="pt-5">
    <v-card flat>
      <v-row justify="center" class="pt-5">
        <v-col cols="12">
          <!-- Workflow Selection -->
          <v-row>
            <v-col cols="10">
              <v-select
                v-model="selectedWorkflow"
                :items="workflows"
                item-text="name"
                return-object
                :label="$t('workflows')"
                outlined
                dense
                @change="onWorkflowSelect"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-btn @click="addWorkflow" icon small>
                <v-icon color="black">mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Workflow Details (shown only if a workflow is selected) -->
          <div v-if="selectedWorkflow">
            <v-text-field dense
              v-model="selectedWorkflow.name"
              outlined
              :label="$t('workflow name')"
            ></v-text-field>

            <!-- Workflow Condition -->
            <v-row>            
              <v-col cols="2">
                <v-label class="label_l">{{$t('when')}}</v-label>
              </v-col>
              <v-col cols="5">
                <v-select
                  v-model="selectedWorkflow.functionName"
                  :items="selectedConfiguration.functions"
                  item-text="name"
                  item-value="name"                  
                  :label="$t('select_function')"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-label class="label_l">{{$t('is_called')}}</v-label>
              </v-col>
            </v-row>

            <!-- Workflow Actions -->
            <div v-for="(action, index) in selectedWorkflow.actions" :key="index">
              <v-row>
                <v-col cols="1">
                  <v-label class="label_l">{{$t('then')}}</v-label>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="action.code"
                    :items="actions"
                    :item-text="action => $t(action.name)"
                    item-value="code"
                    :label="$t('select_action')"
                    outlined
                    dense
                    @change="onActionChange(action)"
                  ></v-select>
                </v-col>

                <!-- Display 'TO' label and Phone Number only when AC1 (Send SMS) is selected -->
                <v-col cols="1" v-if="action.code === 'AC1'">
                  <v-label class="label_l">{{$t('to')}}</v-label>
                </v-col>
                <v-col cols="6" v-if="action.code === 'AC1'">
                  <v-text-field dense
                    v-model="action.targets[0]"
                    outlined
                    :label="$t('phonenumber')"
                  ></v-text-field>
                </v-col>
              </v-row>
                          
              <v-textarea v-if="action.code === 'AC1'" dense height="150"
                v-model="action.message"
                outlined
                :label="$t('message')"
              ></v-textarea>
            </div>

            <!-- Conversation Values and Function Parameters -->
            <v-row v-if="selectedWorkflow.actions.some(a => a.code === 'AC1')">
              <v-col cols="6">
                <v-simple-table dense fixed-header height="200" style="border: 1px solid black;">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>{{$t('conversation_values')}}</th>
                        <th>                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in conversationValues" :key="index">
                        <td>{{ value }}</td>
                        <td>
                          <v-btn icon small @click="insertValue(value)">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="6">
                <v-simple-table dense fixed-header height="200" style="border: 1px solid black;">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>{{$t('function_values')}}</th>
                        <th>                         
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(param, index) in functionParameters" :key="index">
                        <td>{{ param }}</td>
                        <td>
                          <v-btn icon small @click="insertParameter(param)">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <!-- Save and Cancel Buttons -->
            <v-row justify="center" class="pb-3">
              <v-col cols="2">
                <v-btn @click="saveWorkflow"
                  :disabled="!hasWorkflowChanges"
                  color="primary"
                >{{$t('save')}}</v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn @click="cancelChanges"
                  :disabled="!hasWorkflowChanges"
                  color="secondary"
                >{{$t('cancel')}}</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { EventBus } from '../../eventBus.js';
import axios from 'axios';
export default {
  name: 'AiFunctionsTabWorkflow',
  data: () => ({
    selectedWorkflow: null, // No workflow selected initially
    workflows: [],
    selectedFunction: null, // Holds the selected function (condition)    
    actions: [
      { name: 'send_sms', code: 'AC1' }
    ], // Fixed list of actions, with only 'Send SMS'
    conversationValues: ['conversation_created', 'conversation_type'], // fixed values that are available in each conversation
    functionParameters: [], // Dynamically populated
  }),
  watch: {
    'selectedWorkflow.functionName': function(newFunctionName) {
      // Find the selected function based on its name
      const selectedFunction = this.selectedConfiguration.functions.find(
        func => func.name === newFunctionName
      );
      
      // Populate functionParameters based on the selected function
      if (selectedFunction && selectedFunction.parameters && selectedFunction.parameters.properties) {
        this.functionParameters = Object.keys(selectedFunction.parameters.properties);
      } else {
        this.functionParameters = [];
      }
    }
  },
  computed: {
    selectedConfiguration: {
      get() {
        return this.$store.getters.selectedConfiguration;
      }
    },
    hasWorkflowChanges() {
      // Logic to determine if there are changes
      return !!this.selectedWorkflow; // Placeholder for actual change detection logic
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.getWorkflows();
    },
    async getWorkflows() {     
      EventBus.$emit('show-overlay');
      const token = localStorage.getItem('userToken');
      const configurationId = this.selectedConfiguration._id;

      try {
        const response = await axios.get(`${this.$config.configurationServer}/workflows`, {
          headers: { 'Authorization': `Bearer ${token}` },
          params: { configuration_id: configurationId }
        });
        this.workflows = response.data;
      } catch (error) {
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: error });
      } finally {
        EventBus.$emit('hide-overlay');
      }
    },
    
    onWorkflowSelect() {
      // Initialize the function and actions based on the selected workflow
      this.selectedFunction = this.selectedWorkflow.functionName || this.selectedConfiguration.functions[0];
      if (!this.selectedWorkflow.actions || !this.selectedWorkflow.actions.length) {
        this.selectedWorkflow.actions = [{ code: '', targets: [''], message: '' }];
      }
    },

    addWorkflow() {
      // Logic to add a new workflow
      const newWorkflow = {
        configurationId: this.selectedConfiguration._id,
        name: 'New Workflow',
        functionName: '',
        actions: [{ code: '', targets: [''], message: '' }],
      };
      this.workflows.push(newWorkflow);
      this.selectedWorkflow = newWorkflow; // Set new workflow as selected for editing
      this.onWorkflowSelect(); // Ensure function (condition) and action are initialized
    },

    onActionChange(action) {
      // Handle changes to the action
      if (action.code === 'AC1') {
        // Initialize targets if not set
        if (!action.targets || !action.targets.length) {
          action.targets = [''];
        }
        // Initialize message if not set
        if (!action.message) {
          action.message = '';
        }
      }
    },

    async saveWorkflow() {
      // Save the workflows
      EventBus.$emit('show-overlay');
      const token = localStorage.getItem('userToken');
      const configurationId = this.selectedConfiguration._id;

      try {
        await axios.post(`${this.$config.configurationServer}/workflows`, {
          configurationId: configurationId,
          workflows: this.workflows,
        }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: 'Workflows saved successfully' });
      } catch (error) {
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: 'Error saving workflows' });
      } finally {
        EventBus.$emit('hide-overlay');
      }
    },

    cancelChanges() {
      // Reset selected workflow
      this.selectedWorkflow = null; // Deselect the current workflow
    },

    insertValue(value) {
      // Insert conversation value into the message
      if (this.selectedWorkflow && this.selectedWorkflow.actions.length) {
        this.selectedWorkflow.actions.forEach(action => {
          if (action.code === 'AC1') {
            action.message += `{${value}}`;
          }
        });
      }
    },

    insertParameter(param) {
      // Insert function parameter into the message
      if (this.selectedWorkflow && this.selectedWorkflow.actions.length) {
        this.selectedWorkflow.actions.forEach(action => {
          if (action.code === 'AC1') {
            action.message += `{${param}}`;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.label_l {
  font-size: 20px;
}
</style>
